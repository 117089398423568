import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { Footer, Header, SEO } from '@/components'

export default (props) => {
  const {} = useTranslation()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')

  const { title, list } = pageLocale

  return (
    <>
      <SEO
        title={seoLocale.howToApply.title}
        description={seoLocale.howToApply.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Careers at iMile, iMile jobs, Work for iMile, iMile delivery job, job opportunities iMile, Work at iMile'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/csp/howToApply' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/1c763be06e4165a95c46d50a3039cf28/d5f9a/banner.webp'
        />
        <meta
          property='og:title'
          content='Application Guide | Embark on Your CSP Journey with iMile'
        />
        <meta
          property='og:description'
          content='Ready to shape your future? Step into the world of iMile Global Channel Service Partnership today! Your journey towards owning a thriving business begins here.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          width: '100vw',
          '.bg-img': {
            height: ['100%', '100%', '555px', '705px'],
            mt: ['55px', '55px', '90px'],
            width: '100%',
          },
        }}
      >
        {isPc ? (
          <Box component='img' src='/images/HowtoApply.jpg' className='bg-img'></Box>
        ) : (
          <Box component='img' src='/images/HowtoApplyPhone.jpg' className='bg-img'></Box>
        )}
      </Box>
      {isPc ? (
        <Box sx={{ background: '#F6F8FB', padding: '40px 0 60px' }}>
          <Box
            sx={{
              textAlign: 'center',
              lineHeight: '52px',
              fontSize: '38px',
              fontWeight: '700',
              paddingTop: '40px',
              color: '#000',
            }}
          >
            {title}
          </Box>
          {list.map((item, idx) => (
            <Box sx={{ padding: '15px 70px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '56px',
                    height: '56px',
                    position: 'relative',
                    img: {
                      width: '56px',
                    },
                  }}
                >
                  <Box component='img' src='/images/step.svg' />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '20%',
                      left: '30%',
                      color: '#fff',
                      fontSize: '26px',
                      fontWeight: 'bold',
                    }}
                  >{`0${idx + 1}`}</Box>
                </Box>
                <Box
                  sx={{
                    lineHeight: '32px',
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#000',
                    padding: '0 30px',
                  }}
                >
                  {item.title}
                </Box>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', height: 'max-content' }}>
                <Box
                  sx={{
                    width: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      top: '15px',
                      width: '2px',
                      height: '100%',
                      background: '#7582C7',
                      margin: '0 27px',
                      opacity: idx + 1 === list.length ? 0 : 0.2,
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    flex: '1',
                    background: '#fff',
                    margin: '0 10px',
                    padding: '14px 20px',
                    borderRadius: '12px',
                  }}
                >
                  {item.discription}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ background: '#F6F8FB', padding: '0 0 2rem' }}>
          <Box
            sx={{
              textAlign: 'center',
              lineHeight: '52px',
              fontSize: '1.6rem',
              fontWeight: '700',
              paddingTop: '2rem',
              color: '#000',
            }}
          >
            {title}
          </Box>
          {list.map((item, idx) => (
            <Box sx={{ padding: '1rem 1.5rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '56px',
                    height: '56px',
                    position: 'relative',
                    img: {
                      width: '56px',
                    },
                  }}
                >
                  <Box component='img' src='/images/step.svg' />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '20%',
                      left: '30%',
                      color: '#fff',
                      fontSize: '1.3rem',
                      fontWeight: 'bold',
                    }}
                  >{`0${idx + 1}`}</Box>
                </Box>
                <Box
                  sx={{
                    lineHeight: '32px',
                    fontSize: '1rem',
                    fontWeight: '700',
                    color: '#000',
                    padding: '0 1.5rem',
                  }}
                >
                  {item.title}
                </Box>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', height: 'max-content' }}>
                <Box
                  sx={{
                    width: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      top: '15px',
                      width: '2px',
                      height: '100%',
                      background: '#7582C7',
                      margin: '0 27px',
                      opacity: idx + 1 === list.length ? 0 : 0.2,
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    flex: '1',
                    background: '#fff',
                    margin: '0 10px',
                    padding: '14px 20px',
                    borderRadius: '12px',
                  }}
                >
                  {item.discription}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["howToApply"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
